import Axios from "axios";
import {setupCache} from "axios-cache-adapter";

const dev = process.env.REACT_APP_ENV === 'develop';

export const apiAuthPage = true === dev
    ? 'https://sourcecreative.dev-extremereach.com/Login/SourceAuthenticate'
    : 'https://sourcecreative.extremereach.com/Login/SourceAuthenticate';

const apiBaseHost = true === dev
    ? 'https://sourcecreative.dev-extremereach.com/externalapis'
    : 'https://sourcecreative.extremereach.com/externalapis';

export const apiToken = process.env.REACT_APP_SOURCE_API_KEY;

const apiCache = setupCache({
    maxAge: 5 * 60 * 1000
})

export const apiClient = Axios.create({
    baseURL: apiBaseHost,
    headers: {credentials: 'include'},
    withCredentials: true,
    adapter: apiCache.adapter,
});

export const returnUrl = (client) => {
    return `/cmspage/103898/slate_importer_redirect?destination=/index.html?client=${client}`;
};

export const checkIsAuthenticatedUser = () =>
    apiClient.get(`${apiBaseHost}/source/IsUserAuthenticated?AuthorizationToken=${apiToken}`, {
        cache: {
            ignoreCache: true
        }
    });

export const fetchSourceSearchResult = (search = {
    SpotTitle: '',
    BrandName: '',
    keyword: ''
}, page = 1, limit, sortColumn = 'SpotAvailableDateTime', sortOrder = 1) => {
    Object.keys(search).forEach((key) => (search[key] == null || search[key] === '') && delete search[key]);
    const data = {
        AuthorizationToken: apiToken,
        PageSize: limit,
        PageNumber: page,
        SortColumn: sortColumn,
        SortDirection: sortOrder,
        ...search
    };
    const params = (new URLSearchParams(data)).toString();

    return apiClient.get(`${apiBaseHost}/source/SearchSpots?${params}`, {
        cache: {
            exclude: {
                query: false
            }
        }
    });
};

export const fetchSourceSpots = async (spots) => {
    if (!Array.isArray(spots)) {
        console.error('You need to pass an array with the id of the spots.');
    }

    const data = {
        AuthorizationToken: apiToken,
    };
    const params = (new URLSearchParams(data)).toString();

    let spotsParams = '';
    spots.forEach(spotId => {
        spotsParams = spotsParams + '&SpotIds=' + spotId
    });

    return apiClient.get(`${apiBaseHost}/source/searchSpotsTrusted?${params}${spotsParams}`);
};

export const fetchSpotDetail = (spotId) => apiClient.get(
    `${apiBaseHost}/source/SpotDetail?AuthorizationToken=${apiToken}&SpotId=${spotId}`,
    {
        cache: {
            exclude: {
                query: false
            }
        }
    }
);

export const fetchSpotDetailTrusted = (spotId) => apiClient.get(
    `${apiBaseHost}/source/spotTrustedDetail?AuthorizationToken=${apiToken}&SpotId=${spotId}`,
    {
        cache: {
            exclude: {
                query: false
            }
        }
    }
);
