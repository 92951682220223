import React from "react";
import cn from 'classnames';
import DelayInput from "react-delay-input";

function SelectedSpotsHeader({csUser, spotsCount, isSuccess, client, setClient, inQueue})
{
    if (false === isSuccess) {
        if (csUser) {
            return (
                <div className="from-source-modal__intro">
                    <h1>Add spots to</h1>
                    <DelayInput minLength={2} delayTimeout={500} onChange={e => setClient(e.target.value)}
                                value={client} placeholder="Client ID..." />
                    <p>Make sure you use the original client ID, not their alias</p>
                </div>
            );
        }

        return (
            <div className="from-source-modal__intro">
                <h1>Add spots to your Slate</h1>
            </div>
        )
    }

    return (
        <div className={cn('from-source-modal__intro', { 'from-source-modal__intro--success': !inQueue })}>
            <h1>{spotsCount} {spotsCount > 1 ? 'spots have' : 'spot has'} been successfully<br/>
                added to your {inQueue ? 'queue' : 'Slate'}</h1>
        </div>
    );
}

export default SelectedSpotsHeader;