// @flow

import React from "react";
import ReactPaginate from "react-paginate";

const pageRangeDisplayed = 5;
const marginPagesDisplayed = 3;

const CustomPaginator = (
    {rowsPerPage, rowCount, onChangePage, currentPage}:
        {rowsPerPage: number, rowCount: number, onChangePage: any, currentPage: {selected: number}}
    ) => {
    let current = currentPage.selected || 0;

    return <div className={'pagination'}>
        <ReactPaginate
            pageCount={Math.ceil(rowCount / rowsPerPage)}
            pageRangeDisplayed={pageRangeDisplayed}
            marginPagesDisplayed={marginPagesDisplayed}
            onPageChange={onChangePage}
            forcePage={current}
            activeClassName={"current"}
        />
    </div>;
}

export default CustomPaginator;
