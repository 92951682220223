import React, {useEffect, useMemo, useState} from "react";
import DataTable from "react-data-table-component";
import CustomPaginator from "./CustomPaginator";
import {toast} from "react-toastify";
import Loader from "react-spinners/PuffLoader";
import {useStoreActions, useStoreState} from "easy-peasy";
import {Thumbnail} from "./ThumbnailDataTableCell";
import {buildClientInstanceUri} from "../api/SlateApi";
import Moment from "react-moment";
import {fetchSourceSearchResult} from "../api/SourceApi";

function BrowseSpotsDataTable() {
    const urlSearch = new URLSearchParams(window.location.search);
    const defaultLimitPerPage = urlSearch.has('ipp') ? parseInt(urlSearch.get('ipp')) : 25;
    const client = urlSearch.get('client');

    const spotsData = useStoreState((state) => state.spots.data)
    const data = useMemo(() => spotsData, [spotsData])
    const clearSelected = useStoreState((state) => state.spots.clearSelected)

    const setSpots = useStoreActions((action) => action.setSpotsFromSource)
    const setLoggedStatus = useStoreActions((action) => action.setLoggedStatus)
    const fetchSpotsDetails = useStoreActions((action) => action.fetchSpotsDetails)
    const setSelectedSpots = useStoreActions((action) => action.setSelectedSpots)

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [sorting, setSorting] = useState({field: 'SpotAvailableDateTime', direction: 1});
    const search = useStoreState(state => state.search);

    const handleRowSelectChange = (e) => {
        setSelectedSpots(e.selectedRows);
    };

    const fetchSpotsFromSource = async page => {
        setLoading(true);

        let response = null;
        try {
            response = await fetchSourceSearchResult(search, page, defaultLimitPerPage, sorting.field, sorting.direction);

            setSpots(response.data.Items);
            setTotalRows(response.data.TotalItems);
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setSpots([])
            } else if (e.response && e.response.status === 401) {
                setLoggedStatus(false);
                toast.error('Authorization has been denied for this request.', {
                    toastId: "unauthorized",
                    autoClose: false
                });
            } else {
                toast.error('Unable to fetch spots from Source Creative. Trying again within 10 sec.', {
                    toastId: "source_fetch_issue",
                });
                setTimeout(function () {
                    fetchSpotsFromSource(1);
                }, 10000);
            }
        }
        setLoading(false);
        fetchSpotsDetails(client);
    };

    const handlePageChange = page => {
        fetchSpotsFromSource(page.selected + 1);
    };

    const handleSortResult = (sort, direction) => {
        const fieldsMap = {
            'title': 'SpotTitle',
            'client': 'BrandName',
            'release_date': 'SpotAvailableDateTime',
        }
        const fieldSelector = sort.selector;

        setSorting({field: fieldsMap[fieldSelector], direction: direction.toLowerCase() === 'asc' ? 0 : 1});
    };

    useEffect(() => {
        fetchSpotsFromSource(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, sorting]);

    const columns = [
        {
            name: '',
            cell: row => {
                if (!row.hasStream()) {
                    return <></>
                }
                return row.loaded === true ? <Thumbnail key={row.id} item={row}/> : <Loader color={'#36D7B7'}/>;
            },
            center: true,
        },
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            grow: 2
        },
        {
            name: 'Brand',
            selector: row => row.client,
            sortable: true
        },
        {
            name: 'Director',
            selector: row => row.director,
            sortable: false
        },
        {
            name: 'Release date',
            selector: row => row.releaseDate,
            center: true,
            sortable: true,
            cell: row => <Moment>{row.releaseDate}</Moment>
        },
        {
            name: '',
            center: true,
            cell: row => {
                return row.isImported()
                    ? <a href={`${buildClientInstanceUri(client)}/admin/media/edit/${row.mediaId}`} target="_blank" rel="noreferrer noopener"><i className="view"/></a>
                    : ""
            },
            grow: 0.5
        }
    ];

    const conditionalRowStyles = [
        {
            when: row => !row.loaded,
            style: {
                color: '#ccc'
            }
        },
        {
            when: row => !row.hasStream(),
            style: {
                backgroundColor: '#fff6f6',
            },
        },
        {
            when: row => row.isImported(),
            style: {
                backgroundColor: '#f5fff8',
            },
        },
    ];

    return (
        <div className={"module slate__clip-list"}>
            <DataTable columns={columns} data={data}
                       noHeader
                       selectableRows
                       onSelectedRowsChange={handleRowSelectChange}
                       selectableRowDisabled={row => !row.isEncoded()}
                       conditionalRowStyles={conditionalRowStyles}
                       clearSelectedRows={clearSelected}
                       progressPending={loading}
                       sortServer
                       defaultSortField={'release_date'}
                       defaultSortAsc={false}
                       onSort={handleSortResult}
                       pagination
                       paginationServer
                       paginationTotalRows={totalRows}
                       paginationPerPage={defaultLimitPerPage}
                       paginationComponent={CustomPaginator}
                       onChangePage={handlePageChange}
                       progressComponent={<Loader color={'#36D7B7'} className="loading"/>}
                       persistTableHead
            />
        </div>
    );
}

export default BrowseSpotsDataTable;
