import React, {useState} from "react";
import DelayInput from "react-delay-input";
import {toast} from 'react-toastify';
import {useStoreActions, useStoreState} from "easy-peasy";
import {postSpotsToImporterQueue} from "../api/SlateApi";

function SearchBar() {
    const searchInputTimeout = 500;
    const minSearchLength = 3;

    const urlSearch = new URLSearchParams(window.location.search);
    const isCS = urlSearch.has('cs');
    const client = urlSearch.get('client');

    const [, setTitle] = useState('');
    const [, setBrand] = useState('');
    const [, setKeyword] = useState('');
    const [transcode, setTranscode] = useState(false);
    const [isImporting, setImporting] = useState(false);

    const handleSearch = useStoreActions((actions) => actions.setSearchParams);

    const selectedSpots = useStoreState((state) => state.spots.selected);
    const clearSelected = useStoreActions((action) => action.clearSelectedSpots)
    const markSpotsAsImported = useStoreActions((action) => action.markSpotsAsImported)

    const handleBrand = e => {
        setBrand(e.target.value);
        handleSearch({'BrandName': e.target.value});
    };
    const handleTitle = e => {
        setTitle(e.target.value);
        handleSearch({'SpotTitle': e.target.value});
    }
    const handleKeyword = e => {
        setKeyword(e.target.value);
        handleSearch({'keyword': e.target.value});
    }

    const handleImport = async e => {
        e.preventDefault();

        if (selectedSpots.length < 1) {
            return toast.error('You must select at least one spot.', {autoClose: 3000});
        }

        setImporting(true);

        try {
            const response = await postSpotsToImporterQueue(client, selectedSpots, transcode);
            if (response.status === 200) {
                setImporting(false);
                clearSelected();

                if (response?.data?.response) {
                    const spots = JSON.parse(response.data.response).spots;
                    markSpotsAsImported(spots || []);
                    toast.success(`The selected (${selectedSpots.length}) records have been added to your library.`);
                } else {
                    toast.success(`The selected (${selectedSpots.length}) records have been added to the import queue.`);
                }
            } else {
                toast.warn('Something goes wrong.');
            }
        } catch (e) {
            toast.error('Unable to send request to import queue. Please try again in a moment.');
        }
    }

    return (
        <div className={"search"}>
            <div className="search__input--form left">
                <fieldset className="search__input--wrapper">
                    <DelayInput onChange={handleBrand} delayTimeout={searchInputTimeout} minLength={minSearchLength}
                                className="search__input" type="text" placeholder="Search by brand..."/>
                    <span data-icon="f" className="search__input-icon"/>
                </fieldset>
                <fieldset className="search__input--wrapper">
                    <DelayInput onChange={handleTitle} delayTimeout={searchInputTimeout} minLength={minSearchLength}
                                className="search__input" type="text" placeholder="Search by media title..."/>
                    <span data-icon="f" className="search__input-icon"/>
                </fieldset>
                <fieldset className="search__input--wrapper">
                    <DelayInput onChange={handleKeyword} delayTimeout={searchInputTimeout} minLength={minSearchLength}
                                className="search__input" type="text" placeholder="Search by keywords (comma separated)"/>
                    <span data-icon="f" className="search__input-icon"/>
                </fieldset>
            </div>
            <div className="right">
                {isImporting ?
                    <button className="button button--primary button--icon-spin">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z"/>
                        </svg>
                        <span>Importing</span>
                    </button> :
                    <button className="button button--primary" disabled={selectedSpots.length < 1} onClick={handleImport}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/>
                        </svg>
                        {selectedSpots.length === 1 ? (
                            <span>Add spot to Slate</span>
                        ) : (
                            <span>Add {selectedSpots.length} spots to Slate</span>
                        )}
                    </button>
                }
            </div>

            {isCS ? (
                <div className="csbar">
                    <input type="checkbox" name="transcode" id="transcode"
                           onChange={e => setTranscode(e.target.value)}/>
                    <label htmlFor="transcode">Transcode</label>
                </div>
            ) : (<></>)}

        </div>
    );
}

export default SearchBar;
