import {action, thunk} from "easy-peasy";
import {SpotObject} from "./components/SpotObject";
import {toast} from "react-toastify";
import {fetchImportedSpots} from "./api/SlateApi";
import {fetchSpotDetail, fetchSpotDetailTrusted} from "./api/SourceApi";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    isLogged: false,
    setLoggedStatus: action((state, payload) => {
        state.isLogged = payload
    }),
    //spots
    spots: {
        data: [],
        selected: [],
        clearSelected: false,
    },
    setSpotsFromSource: action((state, payload) => {
        state.spots.data = payload.map(item => {
            return SpotObject.createFromSourceObject(item);
        });
    }),
    fetchSpotsDetails: thunk(async (actions, payload, helpers) => {
        const {spots: {data}} = helpers.getState();
        const spotsIds = data.map(spot => spot.id);

        try {
            const importedSpotsResponse = await fetchImportedSpots(payload, spotsIds);
            actions.setImportedSpots(importedSpotsResponse.data.spots || []);
        } catch (e) {
            toast.error('Unable to check status of spots in your library.', {
                toastId: "slate_fetch_imported_failed",
            });
        }

        const requests = [];
        for (const item of data) {
            if (!item.hasStream()) {
                continue;
            }

            requests.push(fetchSpotDetail(item.id));
        }

        try {
            const results = await Promise.allSettled(requests);
            results.map(result => {
                if (result.status === 'fulfilled') {
                    actions.setSpotDetails({id: result.value.data.SpotId, data: result.value.data})
                } else {
                    const url = new URL(result.reason.request.responseURL);
                    actions.setFetchStatusToFailed(url.searchParams.get('SpotId'))
                    console.error('Unable to fetch SpotId ', url.searchParams.get('SpotId'));
                }

                return result;
            });
        } catch (err) {
            console.error('Fetching spots details in parallel has failed', err)
        }
    }),
    setSpotDetails: action((state, payload) => {
        const {id: spotId, data} = payload
        state.spots.data.find(spot => spot.id === spotId)?.setDetail(data);
        state.spots.data = [
            ...state.spots.data
        ];
    }),
    setFetchStatusToFailed: action((state, payload) => {
        state.spots.data.find(spot => spot.id === payload)?.setFailed();
        state.spots.data = [
            ...state.spots.data
        ];
    }),
    setFetchTrustedStatusToFailed: action((state, payload) => {
        state.spots.data.find(spot => spot.rawData.SpotId === payload)?.setFailed();
        state.spots.data = [
            ...state.spots.data
        ];
    }),
    removeSpot: action((state, payload) => {
        state.spots.data = [
            ...state.spots.data.filter(spot => spot.id !== payload)
        ];
    }),
    fetchSpotsTrustedDetails: thunk(async (actions, payload, helpers) => {
        const {spots: {data}} = helpers.getState();

        const requests = [];
        for (const item of data) {
            requests.push(fetchSpotDetailTrusted(item.rawData.SpotId));
        }

        try {
            const results = await Promise.allSettled(requests);
            results.map(result => {
                if (result.status === 'fulfilled') {
                    actions.setSpotDetails({id: result.value.data.ApiId, data: result.value.data})
                } else {
                    const url = new URL(result.reason.request.responseURL);
                    actions.setFetchTrustedStatusToFailed(url.searchParams.get('SpotId'))
                    console.error('Unable to fetch SpotId ', url.searchParams.get('SpotId'));
                }

                return result;
            });
        } catch (err) {
            console.error('Fetching spots details in parallel has failed', err)
        }
    }),
    setImportedSpots: action((state, payload) => {
        Object.entries(payload).forEach((spot) => {
            const spotId = spot[0];
            const status = spot[1];
            state.spots.data.find(spot => spot.id === spotId)
                .markAsImported(status.media_id, status.status)
        });
        state.spots.data = [
            ...state.spots.data
        ];
    }),
    markSpotsAsImported: action((state, payload) => {
        Object.entries(payload).forEach(([spotId, mediaId]) => {
            state?.spots.data.find(spot => spot.id === spotId)
                .markAsImported(mediaId, 0);
        });

        state.spots.data = [
            ...state.spots.data
        ];
    }),
    selectAllSpots: action((state) => {
        state.spots.selected = state.spots.date;
    }),
    setSelectedSpots: action((state, payload) => {
        state.spots.clearSelected = false
        state.spots.selected = payload
    }),
    setSelectedToggle: action((state, payload) => {
        state.spots.clearSelected = true
    }),
    clearSelectedSpots: thunk( (actions) => {
        actions.setSelectedToggle()

        setTimeout(function () {
            actions.setSelectedSpots([])
        }, 500);
    }),
    //search
    search: {
        SpotTitle: '',
        BrandName: '',
        keyword: ''
    },
    setSearchParams: action((state, payload) => {
        state.search = {...state.search, ...payload}
    })
};