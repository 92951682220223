import React from 'react';
import store from "./store";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {StoreProvider} from "easy-peasy";
import Moment from "react-moment";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import BrowseSpotsApp from "./BrowseSpotsApp";
import SelectedSpotsApp from "./SelectedSpotsApp";
import {Navigate} from "react-router";

Moment.globalFormat = 'D MMM YYYY';

function App()
{
    const autoClose = 5000; //5 sec

    return (<StoreProvider store={store}>
        <ToastContainer position="bottom-right" autoClose={autoClose}/>
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<BrowseSpotsApp/>}/>
                    <Route path="index.html" element={<BrowseSpotsApp/>}/>
                    <Route path="selected" element={<SelectedSpotsApp/>}/>
                    <Route path="*" element={<Navigate to="/" />}/>
                </Routes>
            </BrowserRouter>
        </div>
    </StoreProvider>);
}

export default App;
