import React, {useState} from "react";
import Modal from 'react-modal';
import ReactFlowPlayer from "react-flow-player/dist/react-flow-player";

const flowPlayerLicenseKey = '$' + process.env.REACT_APP_FLOWPLAYER_LICENSE_KEY;

Modal.setAppElement('#root');

export function Thumbnail(props) {
    return (
        <div className="list-view__column list-view__column--thumbnail">
            <div className="library__image__wrapper">
                <img src={props.item.getThumbnail()} className="no-itemselect library__image" alt={props.item.id} title={props.item.title}/>
                {props.item.hasStream() ? <PreviewSpot {...props} /> : <MissingStream {...props}/>}
            </div>
        </div>
    );
}

function PreviewSpot(props) {
    const [modalState, handleModal] = useState(false);
    const openModal = () => handleModal(true);
    const closeModal = () => handleModal(false);

    return (
        <div className="spot-preview">
            <a href="#preview" className="library__image__overlay" onClick={openModal}>▶&nbsp;Preview</a>
            <Modal isOpen={modalState} shouldCloseOnOverlayClick={true}
                   className={"clip-preview__overlay lg-outer lg-start-zoom lg-use-css3 lg-css3 lg-slide lg-grab lg-show-after-load lg-visible"}
                   overlayClassName={"modal_overlay"}>
                <div className="lg-toolbar lg-group">
                    <span className="lg-close lg-icon" onClick={closeModal}></span>
                </div>
                <div className="lg-sub-html"><span data-currently-playing-credits="">{props.item.title}</span></div>
                <div className="lg-item lg-loaded lg-current lg-complete">
                    <div className="lg-img-wrap">
                        <div className="slate-player" data-player="clip-preview">
                            <ReactFlowPlayer
                                playerInitScript="//development.slatecdn.net/development/6f52fe1c/bundles/slateslate/js/plugins/flowplayer/flowplayer.min.js"
                                playerId="reactFlowPlayer"
                                licenseKey={flowPlayerLicenseKey}
                                sources={[
                                    {
                                        type: "video/mp4",
                                        src: props.item.stream,
                                        poster: props.item.getThumbnail(),
                                        title: '',
                                        autoplay: true,
                                    }
                                ]}
                            />;
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

function MissingStream(props) {
    return (
        <>
            {!props.item.isEncoded() ?
                <a href="#notencoded" className="library__image__overlay">Not encoded yet.</a> :
                <div className="spot-missing-stream"/>
            }
        </>
    )
}