import Axios from "axios";

const env = process.env.REACT_APP_ENV;
const clientApiHost = process.env.REACT_APP_SLATE_DOMAIN;

export const buildClientInstanceUri = client => `https://${client}.${clientApiHost}`

const clientSlateApiClient = Axios.create({
    timeout: 5000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

const importerAWSApiClient = Axios.create({
    baseURL: `https://enxmrpg022.execute-api.eu-west-1.amazonaws.com/${env}`,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_SLATE_X_API_KEY
    },
});

export const fetchImportedSpots = async (client, spots) => {
    clientSlateApiClient.defaults.baseURL = `${buildClientInstanceUri(client)}/api/v2`;
    if (client.length < 2) {
        return new Promise(function (resolve, reject) {
            reject('client_name_required');
        });
    }

    return clientSlateApiClient.post('/source/imported-spots', {
        client: client,
        environment: env,
        spots: spots,
    });
}

export const postSpotsToImporterQueue = async function (client, spots, transcode = false) {
    return importerAWSApiClient.post('/', {
        client: client,
        environment: env,
        multiple: true,
        spots: spots,
        transcode: transcode !== false
    });
};
