import React from "react";
import SearchBar from "./components/SearchBar";
import BrowseSpotsDataTable from "./components/BrowseSpotsDataTable";
import {useStoreState} from "easy-peasy";
import SourceLogin from "./components/SourceLogin";
import './styles/slate_base.css';
import './styles/slate_app.css';
import './styles/App.css';

function BrowseSpotsApp()
{
    const isLogged = useStoreState(state => state.isLogged);

    return (<>
        {!isLogged ? <SourceLogin/> : <>
            <SearchBar/>
            <BrowseSpotsDataTable/>
        </>}
    </>);
}

export default BrowseSpotsApp;
