class SpotObject {
    id;
    title;
    description;
    agency;
    client;
    director;
    releaseDate;
    thumbnail;
    stream;
    isStreamLowRes;
    rawData;
    // virtual custom fields
    loaded = false;
    fetchingError = null;
    imported = false;
    mediaId;
    importedStatus = null;

    constructor(id, title, description, agency, client, director, releaseDate, thumbnail, rawData)
    {
        this.id = id;
        this.title = title;
        this.description = description;
        this.agency = agency;
        this.client = client;
        this.director = director;
        this.releaseDate = releaseDate;
        this.thumbnail = thumbnail;
        this.rawData = rawData;

        const [streamUrl, isLowRes] = SpotObject.getStream(rawData);
        if (streamUrl) {
            this.stream = streamUrl;
            this.isLowRes = isLowRes;
        }

        if (rawData["Crew"] || rawData['MediaStatusId']) {
            this.markAsLoaded();
        }
    }

    static createFromSourceObject(rawData)
    {
        const data = rawData;
        const id = data.ApiId || data.SpotId;
        const firstName = data.DirectorFirstName || '';
        const lastName = data.DirectorLastName || '';
        const director = `${firstName} ${lastName}`.trim();

        return new SpotObject(
            id,
            data.SpotTitle,
            data.SpotDescription,
            data.AgencyName,
            data.BrandName,
            director,
            data.SpotAvailableDateTime,
            data.KeyFrameThumbnailUrl,
            rawData
        );
    }

    setFailed()
    {
        this.fetchingError = true;
    }

    setDetail(detail)
    {
        if (detail.hasOwnProperty('TrustedCrew')) {
            detail.Crew = detail.TrustedCrew;
            delete detail.TrustedCrew;
        }

        this.rawData = detail;
        const [streamUrl, isLowRes] = SpotObject.getStream(detail, true);
        if (streamUrl) {
            this.stream = streamUrl;
            this.isStreamLowRes = isLowRes;
        }
        this.markAsLoaded();
    }

    markAsLoaded()
    {
        this.loaded = true;
    }

    markAsImported(mediaId, status)
    {
        this.imported = true;
        this.mediaId = mediaId;
        this.importedStatus = status;
    }

    isImported = () => this.imported;

    isEncoded = () => {
        if (true === [40, 50].includes(this.rawData.StreamingUrlHighRes1080p)) {
            return true;
        }

        return true === [40, 50].includes(this.rawData.StreamingUrlHighResStatus);
    };

    getThumbnail = () => this.thumbnail || '/img/error.svg';

    hasStream = () => undefined !== this.stream && null !== this.stream;

    static getStream = (data, checkStatus = false) => {
        const isEncoded = value => true === [40, 50].includes(value);

        if ((false === checkStatus && data.StreamingUrlHighRes1080p)
            || (true === checkStatus && data.StreamingUrlHighRes1080p && isEncoded(data.StreamingUrlHighRes1080pStatus))) {
            return [data.StreamingUrlHighRes1080p, false];
        }

        if ((false === checkStatus && data.StreamingUrlHighRes)
            || (true === checkStatus && data.StreamingUrlHighRes && isEncoded(data.StreamingUrlHighResStatus))) {
            return [data.StreamingUrlHighRes, false];
        }

        if (data.StreamingUrlLowRes) {
            return [data.StreamingUrlLowRes, true];
        }

        return [null, null];
    };
}

export {SpotObject};
