import React, {useEffect, useState} from "react";
import {apiToken, apiAuthPage, returnUrl, checkIsAuthenticatedUser} from "../api/SourceApi";
import {RingLoader} from "react-spinners";
import {useStoreActions, useStoreState} from "easy-peasy";

const urlSearch = new URLSearchParams(window.location.search);
const client = urlSearch.get('client') || '';

function SourceLogin()
{
    const [isLoading, setLoading] = useState(false);
    const logged = useStoreState(state => state.isLogged)
    const setLoggedStatus = useStoreActions((actions) => actions.setLoggedStatus);

    useEffect(() => {
        const checkIsUserLoggedIn = async () => {
            setLoading(true);
            const response = await checkIsAuthenticatedUser();
            const isLoggedIn = await response.data.userIsAuthenticated;
            setLoggedStatus(isLoggedIn);
            setLoading(false);
        }
        checkIsUserLoggedIn()
    }, [logged, setLoggedStatus]);

    return (
        <>
            {isLoading
                ? <RingLoader color={'#36D7B7'} className="loading"/> : (
                    !logged ? <LoginForm/> : ''
                )
            }
        </>
    );
}

export default SourceLogin;

const LoginForm = () =>
    <div className="login__container">
        <div className="login__box">
            <div className="login__logo">
                <img className="mk-sticky-logo mk-svg"
                     title="The new way creative gets everywhere."
                     alt="The new way creative gets everywhere."
                     src="//d1ko11x0ybxl0h.cloudfront.net/sourcemarketing/production/templating/1547819219958/source-logo.svg"/>
            </div>
            <form id="login-form" className="modal__form" method="post" action={apiAuthPage}>
                <input id="returnUrl" name="returnUrl" type="hidden" value={returnUrl(client)}/>
                <input id="apiKey" name="apiKey" type="hidden" value={apiToken}/>

                <input type="email" id="userName" name="userName" placeholder="Email address"
                       className="login__input login__input--email"/>
                <input type="password" id="password" name="password" placeholder="Password"
                       className="login__input login__input--password"/>
                <input type="text" id="jobid" name="jobid" placeholder="Client / job #"
                       className="login__input login__input--password"/>
                <button type="submit" className="login__button">Log In</button>
            </form>
        </div>
    </div>;
